import React, { useState } from "react";

import { ChevronLeftIcon } from "@heroicons/react/solid";
import ReactMarkdown from "react-markdown";
import { navigate } from "gatsby";
import { createPost } from "../components/utils/firebase";
import slugify from "slugify";

import "../styles/base.css";

export default function BlogEdit() {
  const [articleContent, setArticelContent] = useState("");
  const [articleAuthor, setArticleAuthor] = useState("");
  const [articleTitle, setArticleTitle] = useState("Default title");
  const [articleImageLink, setArticleImageLink] = useState("");

  const onTitleChange = (event) => {
    setArticleTitle(event.target.value);
  };
  const onContentChange = (event) => {
    setArticelContent(event.target.value);
  };
  const onAuthorChange = (event) => {
    setArticleAuthor(event.target.value);
  };
  const onImageChange = (event) => {
    setArticleImageLink(event.target.value);
  };

  const handleCreatePost = async () => {
    let author = articleAuthor;
    let title = articleTitle;
    let slug = slugify(title.toLocaleLowerCase());
    let text = articleContent;
    let createdAt = new Date();
    let hidden = false;
    let imageUrl = articleImageLink;

    alert("Post successfully created!");

    createPost(author, title, slug, text, createdAt, hidden, imageUrl);

    navigate("/blog-edit");
  };

  return (
    <>
      <header className="bg-gray-50">
        <nav
          className="max-w-8xl mx-auto px-2 sm:px-3 lg:px-8"
          aria-label="Top"
        >
          <div className="w-full py-3 flex items-center justify-between border-b border-indigo-500 lg:border-none">
            <div className=" space-x-6 flex items-center justify-between w-full">
              <button
                onClick={() => {
                  navigate("/blog-edit");
                }}
                className="inline-flex items-center bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50"
              >
                <ChevronLeftIcon className="-ml-0.5 mr-2 h-4 w-4"></ChevronLeftIcon>
                Back
              </button>
              <button
                onClick={handleCreatePost}
                className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-green-600 hover:bg-green-50"
              >
                Save article
              </button>
            </div>
          </div>
        </nav>
      </header>
      <div className="relative bg-gray-50 pt-6 pb-20 px-4 sm:px-6  lg:pt-12 lg:pb-28 lg:px-8">
        <div className=" max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
          <div className="editor">
            <div>
              <div className="flex justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Article title
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={onTitleChange}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md  p-2"
                  placeholder={articleTitle}
                  aria-describedby="email-optional"
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="flex justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Article author
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={onAuthorChange}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md  p-2"
                  placeholder={articleAuthor}
                  aria-describedby="email-optional"
                />
              </div>
            </div>
            {/*<div className="mt-6">*/}
            {/*  <div className="flex justify-between">*/}
            {/*    <label*/}
            {/*      htmlFor="email"*/}
            {/*      className="block text-sm font-medium text-gray-700"*/}
            {/*    >*/}
            {/*      Article image link*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*  <div className="mt-1">*/}
            {/*    <input*/}
            {/*      onChange={onImageChange}*/}
            {/*      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md  p-2"*/}
            {/*      placeholder={articleImageLink}*/}
            {/*      aria-describedby="email-optional"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="mt-6">
              <div className="flex justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Article image link
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={onImageChange}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md  p-2"
                  placeholder={articleImageLink}
                  aria-describedby="email-optional"
                />
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="comment"
                className="block text-sm font-medium text-gray-700"
              >
                Article body
              </label>
              <div className="mt-1">
                <textarea
                  rows={30}
                  name="comment"
                  id="comment"
                  onChange={onContentChange}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md  p-2"
                  defaultValue={articleContent}
                />
              </div>
            </div>
          </div>
          <div className="preview">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  {articleTitle}
                </span>
              </h1>
            </div>
            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto article-body">
              <ReactMarkdown>{articleContent}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
